<!-- use on client side only -->
<template>
  <div></div>
</template>
<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();

const props = defineProps({
  to: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: false,
    default: 'redirect',
  },
});

onMounted(() => {
  console.log(`Redirect Component ${props.id}: Redirecting to {${props.to}}`);
  router.push(props.to.toString());
});
</script>